import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "requesting-a-token-deprecated"
    }}>{`Requesting a Token (Deprecated)`}</h1>
    <h2 {...{
      "id": "notice"
    }}>{`Notice`}</h2>
    <p>{`Basic authentication and usage of the token endpoint has been deprecated in the Capella API. Usage of the Capella API will now require an API key. For more information, see `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/authentication/requesting-api-key"
      }}>{`Requesting an API Key`}</a>{`.`}</p>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`The Capella API uses JSON Web Tokens (JWT) to validate a user’s identity. The token endpoint is used to exchange a username and password in a basic authentication header for an authentication token. You must have a valid Capella account to successfully request a token. Authentication tokens will expire after approximately 1 hour.`}</p>
    <h2 {...{
      "id": "requesting-a-token"
    }}>{`Requesting a Token`}</h2>
    <p>{`To request a token, you must first send a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to the token endpoint. Your username and password should be included in the header:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.capellaspace.com/token
Content-Type: 'application/x-www-form-urlencoded'
Authorization: Basic <Base64 username:password>
`}</code></pre>
    <p>{`A successful request will result in the following response:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "accessToken": "eyJ...",
    "expiresIn": 3600,
    "tokenType": "Bearer"
}
`}</code></pre>
    <h2 {...{
      "id": "using-the-token"
    }}>{`Using the Token`}</h2>
    <p>{`To use the token you have requested, include it in the header of requests to other endpoints`}</p>
    <pre><code parentName="pre" {...{}}>{`Authorization: Bearer {{accessToken}}
`}</code></pre>
    <p>{`The token will expire after the time denoted in `}<inlineCode parentName="p">{`expiresIn`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information, see the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/access-entitlement"
      }}>{`Access and Entitlements`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      